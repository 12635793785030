import { forwardRef } from "react"
import { v4 as uuidv4 } from "uuid";
import { Input } from "@/Components/ui/input";
import { Textarea } from "@/Components/ui/textarea";
import { Switch } from "@/Components/ui/switch";
import { RadioGroup, RadioGroupItem } from "@/Components/ui/radio-group"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/Components/ui/select"
import InputLabel from "@/Components/InputLabel";
import { cn } from "@/lib/utils";
import TagsInput from "react-tagsinput";
import 'react-tagsinput/react-tagsinput.css'
import MultiSelect from 'react-select'
import makeAnimated from 'react-select/animated';
import { Label } from "@/Components/ui/label";



const FormInputGroup = forwardRef(({ label, className, inputClass, labelClass = "", type = "text", notes = null, ...rest }, ref) => {
    const uuid = uuidv4()
    return <div className={cn("w-full col-span-1 flex flex-col gap-2", className)}>
        <InputLabel htmlFor={`uid-${uuid}`} className={labelClass}>{label}</InputLabel>
        <Input id={`uid-${uuid}`} type={type} ref={ref} className={inputClass} {...rest} />
        {notes && <small>{notes}</small>}
    </div>
})

const FormTextareaGroup = forwardRef(({ label, labelClass = "", notes = null, ...rest }, ref) => {
    const uuid = uuidv4()
    return <div className="w-full flex flex-col gap-2">
        <InputLabel htmlFor={`uid-${uuid}`} className={labelClass}>{label}</InputLabel>
        <Textarea id={`uid-${uuid}`} ref={ref} {...rest} />
        {notes && <small>{notes}</small>}
    </div>
})

const FormSelectGroup = forwardRef(({ value = undefined, label, className = "", labelClass = "", placeholder = "Select...", options = [], optLabel = "name", optVal = "value", notes = null, ...rest }, ref) => {
    const uuid = uuidv4()
    return <div className={cn("w-full col-span-1 isolate flex flex-col gap-2", className)}>
        <InputLabel htmlFor={`uid-${uuid}`} className={labelClass}>{label}</InputLabel>
        <Select id={`uid-${uuid}`} ref={ref} value={value} {...rest} className={cn("w-full", rest.className || '')} mutiple={false}>
            <SelectTrigger className="w-full">
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
                {options?.map((opt, inx) => <SelectItem key={inx} value={opt[optVal]}>{opt[optLabel]}</SelectItem>)}
            </SelectContent>
        </Select>
        {notes && <small>{notes}</small>}
    </div>
})

const FormRadioGroup = forwardRef(({ label, row = false, labelClass = "", placeholder = "Select...", options = [], optLabel = "name", optVal = "value", ...rest }, ref) => {
    const uuid = uuidv4()
    return <div className="w-full flex flex-col gap-2">
        <InputLabel htmlFor={`uid-${uuid}`} className={cn('cursor-pointer', labelClass)}>{label}</InputLabel>
        <RadioGroup id={`uid-${uuid}`} ref={ref} {...rest}>
            <div className={cn("flex gap-4", row ? "flex-row" : "flex-col")}>
                {options?.map((opt, inx) => <div key={inx} className="flex items-center space-x-2">
                    <RadioGroupItem value={opt[optVal]} id={`radio-${inx}`} />
                    <Label htmlFor={`radio-${inx}`} className="cursor-pointer">{opt[optLabel]}</Label>
                </div>)}
            </div>
        </RadioGroup>
    </div>
})

const FormSwitchGroup = forwardRef(({ label, dense = false, labelClass = "", className = "", containerClass = "", ...rest }, ref) => {
    const uuid = uuidv4()
    return <div className={cn("flex items-center space-x-2", containerClass)}>
        <Switch ref={ref} dense={dense} {...rest} id={`uid-${uuid}`} className={cn(className)} />
        <InputLabel htmlFor={`uid-${uuid}`} className={labelClass}>{label}</InputLabel>
    </div>
})

const FormSwitch2Group = forwardRef(({ label, dense = false, labelClass = "", className = "", containerClass = "", ...rest }, ref) => {
    const uuid = uuidv4()
    return <div className={cn("flex flex-row justify-between items-center w-full space-x-2", containerClass)}>
        <InputLabel htmlFor={`uid-${uuid}`} className={labelClass}>{label}</InputLabel>
        <Switch ref={ref} dense={dense} {...rest} id={`uid-${uuid}`} className={cn(className)} />
    </div>
})

const FormTagsGroup = forwardRef(({ label, labelClass = "", className = "", placeholder, ...rest }, ref) => {
    const uuid = uuidv4()
    return <div id="tag-container" className="flex text-primary flex-col w-full items-center space-y-2">
        <InputLabel htmlFor={`uid-${uuid}`} className={cn("w-full text-left", labelClass)}>{label}</InputLabel>
        <TagsInput onlyUnique={true} ref={ref} {...rest} id={`uid-${uuid}`} className={className} inputProps={{ placeholder }} />
    </div>
})

const FormMultiSelectGroup = forwardRef(({ label, labelClass = "", placeholder = "Select...", options = [], notes = null, ...rest }, ref) => {
    //value:label
    //set default value defaultValue: ['kkk']
    const animatedComponents = makeAnimated();
    const uuid = uuidv4()
    return <div className="flex flex-col items-star justify-center w-full space-y-2">
        <InputLabel htmlFor={`uid-${uuid}`} className={labelClass}>{label}</InputLabel>
        <MultiSelect options={options} ref={ref} {...rest} id={`uid-${uuid}`} isMulti={true} closeMenuOnSelect={false} components={animatedComponents} />
        {notes && <small>{notes}</small>}
    </div>
})

export { FormInputGroup, FormTextareaGroup, FormSelectGroup, FormRadioGroup, FormSwitchGroup, FormSwitch2Group, FormTagsGroup, FormMultiSelectGroup }
